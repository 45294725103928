import React from "react";
import { useState,useEffect } from "react";
import audio from './sound/tone.mp3';
import Button from 'react-bootstrap/Button';
import  ButtonGroup  from "react-bootstrap/ButtonGroup";
import Form from "react-bootstrap/Form";
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import 'bootstrap/dist/css/bootstrap.min.css';
import './Time.css'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'





export default function Timer(props) {
    const [active,setActive] = useState()
    const [time, setTime] = useState(180)
    const [timeLeft, setTimeLeft] = useState(180)
    // const [timeID, setTimeID] = useState()
    const [play,setPlay] = useState(false)
    const [key, setKey] = useState(0)
    const [weather, setWeather] = useState()
    const [temperature, setTemperature] = useState()
    const [refreshTem, setRefreshTem] = useState(0)
    const [qoute, setQoute] = useState()
    const [textTheme,setTextTheme] = useState(`text-${props.theme}`)
    

    Notification.requestPermission();
    useEffect(() => {
        setTextTheme(`text-${props.theme}`)
    },[props.theme])
    console.log(textTheme)

    //add quote 
    useEffect(() => {
        const apikey = 'nIjH2DI1Qh60HLrVlyP95A==iwWffYqR1LT8ESUB'
        const apiUrl = 'https://api.api-ninjas.com/v1/quotes?category=happiness'
        const requestOption  = {
            method: "GET",
            headers: { 'X-Api-Key': apikey},
            contentType: "application/json"
        };
        fetch(apiUrl,requestOption)
            .then(response => {
                if(!response.ok){
                    throw new Error("Somethings is wrong to get the quote")
                }else{
                    return response.json();
                }
            })
            .then(data => {
                setQoute(data[0].quote)
                console.log(data[0].quote)
            })
    },[])

    //add weather forecast
    useEffect(() => {
        const apikey = 'IDdabADumCiSgAgavlVCEy2xD2NHQkTu'
        // const url = '/api/currentconditions/v1/58185'
        const url = 'https://dataservice.accuweather.com/currentconditions/v1/58185'
 
        const query=`?apikey=${apikey}`
        
        fetch(url + query)
            .then(response => {
                if(!response.ok){
                    throw new Error('something is wrong to get Weather infor')
                }else{
                    return response.json();
                }
            })
            .then(data => {
                const weartherResult = data[0].WeatherText
                const temperatureResult = data[0].Temperature.Metric.Value
                setWeather(weartherResult)
                setTemperature(temperatureResult)
                console.log('refreshTem')
            })
        return
       }, [refreshTem]);

    //Show Notification when time is up.
    useEffect(() => {
        if(timeLeft === 0){
            new Notification('Time is up !',{
                body:'Have a great day',
                icon:'./image/timer.png'
            });
            new Audio(audio).play();
        };
        return
       }, [timeLeft]);

    
    useEffect(() => {
        let timeID
        if(active){
            timeID=(setInterval(() => {
                setTimeLeft((prev) => {
                    return prev > 0 ? (prev - 1) : 0
               })
            }, 1000));
            setPlay(true)
        }else{
            clearInterval(timeID)
            setPlay(false)
        }
        return () => clearInterval(timeID)
    },[active])

    // function handleStart(e) {
    //     e.preventDefault();
    //     setTimeID(setInterval(() => {
    //         setTimeLeft((prev) => {
    //             return prev > 0 ? (prev - 1) : 0
    //        })
    //     }, 1000));
        
    //     // setKey(prevKey => prevKey + 1)
    //     setPlay(true);
    // }

    function handleStart() {
        setActive(!active)
    }

    function handleReset(e) {
        e.preventDefault();
        setActive(false)
        const setMin = document.getElementById('setTimeInput').valueAsNumber
        setTime(60*setMin);
        setTimeLeft(60*setMin);
        setKey(prevKey => prevKey + 1)
        setPlay(false);
    }


    function handleInput(e) {
        e.preventDefault();
        setTime(e.target.value*60);
        setTimeLeft(e.target.value*60);
    }

    function handlePause(e) {
        e.preventDefault();
        setActive(false);
        setPlay(false)
    }

    function handleBreak(e) {
        e.preventDefault();
        setTime(300);
        setTimeLeft(300);
    }

    function handelRefresh(e) {
        setRefreshTem((prev) => prev + 1)
    }
   
    function weatherLanChange(weather) {
        switch(props.lan){
            case 'English':
                return <div>Weather: {weather}</div>
            default:
                return <div>天气: {weather}</div>
        }
    }



    // console.log(timeLeft)
    return (
        <div className={`text-${props.theme}`}>
            <CountdownCircleTimer
                key={key}
                isPlaying={play}
                duration={time}
                colors={['#1aa1db', '#bf192f']}
                colorsTime={[7, 0]}
            >
            {({ remainingTime }) => `${Math.floor(remainingTime/60)} Mins : ${remainingTime%60} Seconds`}
           </CountdownCircleTimer>
            <div>
                {/* {props.lan == 'English'?'Weather':'天气'} : {weather}. */}
                {weatherLanChange(weather)}
                {/* <br></br> */}
                Temperature : {temperature} C degree.<Button variant={props.theme} onClick={handelRefresh}>refresh</Button>
            </div>
            <form className={`text-${props.theme}`}>
                <h1>Pomodoro Timer</h1>
                <FloatingLabel label="Set Min">
                    <Form.Control type="number" onChange={handleInput} className="setTime" id='setTimeInput'></Form.Control>
                </FloatingLabel>
                <p>{timeLeft > 0 ? `Time left : ${Math.floor(timeLeft/60)} mins: ${timeLeft % 60} secs `: 'Time is up!' }</p>
                <ButtonGroup>
                    <Button variant={props.theme} onClick={handleStart}>Start</Button>
                    <Button variant={props.theme} onClick={handlePause}>Pause</Button>
                    <Button variant={props.theme} onClick={handleReset}>Reset</Button>
                    <Button variant={props.theme} onClick={handleBreak}>Break</Button>
                </ButtonGroup>
                <p className="quote">
                    {qoute}
                </p>
            </form>
        </div>
    );
}
import './App.css';
import React, { useEffect } from 'react';
import Timer from './Timer.js';
import Todo from './Todo.js';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import Dropdown from 'react-bootstrap/Dropdown'

function App() {
const [CurrentTheme, setCurrentTheme] = useState("light")
const [componentTheme,setComponentTheme] = useState("dark")
const [language,setLanguage] = useState('English')
function themeChange(){
  setCurrentTheme((oldTheme)=>{
    if(oldTheme ==="light"){
      return "dark"
    }else{
      return "light"
    }
  })
  // console.log(`Current is ${CurrentTheme}`)
}

function handleLangugaeChange(e){
  setLanguage(e.target.innerText)
}
useEffect(()=>{
  if(CurrentTheme === 'light'){
    setComponentTheme('dark')
  }else{
    setComponentTheme('light')
  }
},[CurrentTheme])

  return (
      <div data-bs-theme={CurrentTheme} id="themeState" className={`fullPage bg-${CurrentTheme}`}>
          <Dropdown>
            <Dropdown.Toggle>
                Language Select
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <Dropdown.Item onClick={(e) => handleLangugaeChange(e)}>English</Dropdown.Item>
                <Dropdown.Item onClick={(e) => handleLangugaeChange(e)}>简体中文</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Button id="themeChange" onClick={(e)=>themeChange(e,CurrentTheme)}>
            {CurrentTheme === "light" ? "dark" : "light"}
          </Button>
          <div className="App-container">
                {/* <img src={'https://www.svgrepo.com/show/102412/tomato.svg'} className="App-logo" alt="logo" />   */}
                <Timer className='App-timer' theme={componentTheme} lan={language}></Timer>
                <Todo className='App-todo' theme={componentTheme}></Todo>
          </div>
      </div>
  );

}

export default App;

import React from 'react';
import {useState, useEffect} from 'react';
import { v4 as uuidv4 } from 'uuid';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import  FloatingLabel  from 'react-bootstrap/FloatingLabel';
import 'bootstrap/dist/css/bootstrap.min.css';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import moment from 'moment';
import './Todo.css'





export default function Todo(props) {
    let initToDos = [];
    let initDoneToDo = [];

    if(JSON.parse(localStorage.getItem('toDos'))){
         initToDos = JSON.parse(localStorage.getItem('toDos'))
    }

    if(JSON.parse(localStorage.getItem('doneToDos'))){
         initDoneToDo = JSON.parse(localStorage.getItem('doneToDos'))
    }

    const [toDos , setToDo] = useState(initToDos);
    const [doneToDos, setDoneToDo] = useState(initDoneToDo);
    const [newToDo, setNewToDO] = useState()
    const [filteredToDo,setFilteredToDo] = useState()
    const [sortCondition, setSortCondition] = useState('Customized')
    const [isEdit,setIsEdit] = useState()
    // const [showEdit,setShowEdit] = useState("false")
   

    function handleSubmit(e) {
        e.preventDefault(e);
        let now = moment().unix();
        // const newTodoLine = document.getElementById("newTodo").value
        const newTodoLine = newToDo
        if(!newTodoLine) {
            alert('You need to enter content to add New Todo')
        }else{
            document.getElementById("newTodo").value = ''
            setToDo((prev) =>{
                return [...prev, {content:newTodoLine, id:uuidv4(), showEdit:'false',addTime:now,updateTime:now}];
        })
     }
    }

    function handleAddNewToDo(e){
        // console.log(e.target.id)
        setNewToDO(e.target.value)
    }

    function handleSearch(e) {
        e.preventDefault(e);
        const searchTarget = document.getElementById("searchTodo").value.toLowerCase()
        const searchTargetArray = searchTarget.split(" ");
        console.log(searchTargetArray)
            if(searchTarget){
                const filtedToDo = toDos.filter((todo) => {
                    const todoContent = todo.content.toLowerCase()
                    return (searchTargetArray.every((targetWord) => todoContent.includes(targetWord) ))
                })
                console.log(filtedToDo)
                setFilteredToDo(filtedToDo)
            }else{
                setFilteredToDo()
                return;
            }
        }

  
    function handelDelete(e,id) {
        // console.log(`the selected id is ${id}`)
        let selectedId = id
        if(e.target.id === "deleteTodo") {
            setToDo(PrevToDos => PrevToDos.filter(toDo => {
                return toDo.id !== selectedId
            }))
        }else{
            setDoneToDo(PrevDoneToDos => PrevDoneToDos.filter(donTodo => {
                return donTodo.id !== selectedId
            }))
        }
    }

    function handelDone(content,id){
        // console.log(`the selected id is ${id}`)
        let selectedId = id
        setToDo(PrevToDos => PrevToDos.filter(toDo => {
            return toDo.id !== selectedId
        }))

        setDoneToDo((prev) => {
            return [...prev,{content:content, id:selectedId}]
        })
    }

    function handleOnDragEnd(result){
        if(!result.destination){
            return;
        }else{
            const items = Array.from(toDos);
            const [reOrderItems] = items.splice(result.source.index, 1);
            items.splice(result.destination.index,0,reOrderItems);
            setToDo(items)
        }
    }


    const arrayDoneToDos = doneToDos.map((doneToDo) => {
        return (
            <li key={doneToDo.id}>{doneToDo.content}
                <Button variant={props.theme} onClick={(e) => handelDelete(e,doneToDo.id)} id="deleteDoneToDo">X</Button>
            </li>
        )
    })

    function handleSetEdit(e,id) {
        console.log(props.className)
        setToDo(prevToDos => prevToDos.map(prevToDo => {
            if(prevToDo.id === id){
                return {...prevToDo, showEdit:'true', updateTime:moment().unix()}
            }else{
                return {...prevToDo}
            }
        }))
    }


    function handleEdit(e,id){
        console.log(e.target.value)
        setIsEdit('ture')
        setToDo(prevToDos => prevToDos.map(prevToDo => {
            if(prevToDo.id === id){
                return {...prevToDo, content:e.target.value}
            }else{
                return {...prevToDo}
            }
        }))
    }

    function handleBlur(e,item) {
        setIsEdit('false')
        let now = moment().unix();
        setToDo(prevToDos => prevToDos.map(prevToDo => {
            if(prevToDo.id === item.id){
                return {...prevToDo, showEdit:e.target.value,updateTime:now}
            }else{
                return {...prevToDo}
            }
        }))
    }

    useEffect(() => {
        console.log(sortCondition)
        if(sortCondition === "By add time"){
            setToDo((prevToDos) => prevToDos.toSorted((a, b) => {
                return  a.addTime - b.addTime
            }))
            // const timeSortedData = [...toDos].sort((a,b) => {
            //     return a.addTime - b.addTime
            // })
            // setToDo(timeSortedData)
        }else if(sortCondition === "By update time"){
            setToDo((prevToDos) => prevToDos.toSorted((a, b) => {
                return   b.updateTime - a.updateTime 
            }))
            // const timeSortedData = [...toDos].sort((a,b) => {
            //     return  b.updateTime - a.updateTime 
            // })
            // setToDo(timeSortedData)
        }
    },[sortCondition, isEdit])


    function handleChangeSortCodition(e) {
        setSortCondition(e.target.value)
    }


    localStorage.setItem('toDos',JSON.stringify(toDos))
    localStorage.setItem('doneToDos',JSON.stringify(doneToDos))
    // console.log(sortCondition)
    console.log(toDos)
    // console.log(filteredToDo)
    const renderToDo = filteredToDo ? filteredToDo : toDos

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <FloatingLabel label="Enter New Todo">
                    <Form.Control type="textarea" id="newTodo" onChange={handleAddNewToDo}></Form.Control>
                </FloatingLabel>
                <Button variant={props.theme} type='submit'>Add</Button>
            </form>

            <form onSubmit={handleSearch}>
                <FloatingLabel label="Search Todo">
                    <Form.Control type="textarea" id="searchTodo" onChange={handleSearch}></Form.Control>
                </FloatingLabel>
            </form>
            <label className={`text-${props.theme}`}>Sort ToDo By:</label>
            <select value={sortCondition} onChange={e => handleChangeSortCodition(e)}>
                <option value= 'Customized'>Customized</option>
                <option value= 'By add time'>By add time</option>
                <option value= 'By update time'>By update time</option>
            </select>

            <DragDropContext onDragEnd={handleOnDragEnd} >
                <ul className={`text-${props.theme}`}>Todo
                <Droppable droppableId='Root' type='group'>
                {(provided) => {
                    return (
                        <div {...provided.droppableProps} ref={provided.innerRef} className={`text-${props.theme}`}>
                            {renderToDo.map((item,index) => {
                                return (
                                    <Draggable 
                                    draggableId={item.id} 
                                    index={index} 
                                    key={item.id}
                                    >
                                        {(provided) => {
                                            return (
                                                <div 
                                                {...provided.dragHandleProps}
                                                {...provided.draggableProps}
                                                ref={provided.innerRef}
                                                >
                                                    <li key={item.id} >{item.content}
                                                    {item.showEdit === "true" ? <input type='text' value={item.content} onChange={(e) => handleEdit(e,item.id)}  onBlur={e => handleBlur(e,item)}></input> : ""}
                                                    <Button variant={props.theme} onClick={(e) => handleSetEdit(e,item.id)}>Edit</Button>
                                                    <Button variant={props.theme} onClick={() => handelDone(item.content,item.id)}>Done</Button>
                                                    <Button variant={props.theme} onClick={(e) => handelDelete(e,item.id)} id="deleteTodo">X</Button>
                                                    </li>
                                                </div>
                                            )
                                        }}
                                    </Draggable>
                                )
                            })}
                            {provided.placeholder}
                        </div>
                    )
                }}
                
                </Droppable>
                </ul>
                <ul className={`text-${props.theme}`}>Done
                {arrayDoneToDos}     
                </ul>   
            </DragDropContext>


            {/* <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey='0'>
                    <Accordion.Header>UnDo:</Accordion.Header>
                    <Accordion.Body>{arrayTodos}</Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='1'>
                    <Accordion.Header>Done:</Accordion.Header>
                    <Accordion.Body>{arrayDoneToDos}</Accordion.Body>
                </Accordion.Item>
            </Accordion> */}
            {/* <label>UnDo:</label>
            <ul>{arrayTodos}</ul>
            <label>Done:</label>
            <ul>{arrayDoneToDos}</ul> */}
        </div>
    );
}